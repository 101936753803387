import * as React from 'react'
import { useState, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import * as style from './navbar.module.css'

import Logo from '../../assets/images/logo.svg'
import Chevron from '../../assets/icons/chewronUp.svg'
import CircleCheck from '../../assets/icons/circleCheck.svg'
import { multilingualLink } from '../../utils/multilaguagesLink'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const Navbar = ({
  openModal,
  career,
  company,
  contact,
  models,
  process,
  services,
  technology,
  values,
  locale,
}) => {
  const [storedLang, setLangToStorage] = useLocalStorage()

  const {
    langList: { langs },
  } = useStaticQuery(graphql`
    {
      langList {
        langs
      }
    }
  `)
  const { pathname } = useLocation()
  const newPathname = pathname.replace(`/${locale}`, '')
  const links = [
    { path: '/#services', sectionName: services },
    { path: '/#company', sectionName: company },
    { path: '/#values', sectionName: values },
    { path: '/#process', sectionName: process },
    { path: '/#models', sectionName: models },
    { path: '/#tech', sectionName: technology },
    { path: '/career', sectionName: career },
  ]
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [showAllLangs, setShowAllLangs] = useState(false)
  const showMenu = () => {
    setMenuVisibility(!menuVisibility)
  }

  const toggleShowAllLangs = () => {
    setShowAllLangs(prev => !prev)
  }

  const renderFlag = countryName => {
    if (countryName === 'en') {
      return <span className={`fi fi-gb`}></span>
    } else {
      return <span className={`fi fi-${countryName}`}></span>
    }
  }

  const showLangMenu = (device, langs) => {
    return (
      <div
        className={`${
          device === 'desktop'
            ? style.langWrapperDesktop
            : style.langWrapperMobile
        }`}
      >
        <div className={style.navLangWrapper}>
          <div className={style.flagStyle}>{renderFlag(locale)}</div>
          <div
            className={`${style.dropdownArrow} ${
              showAllLangs && style.dropdownShow
            }`}
            onClick={toggleShowAllLangs}
          >
            <Chevron />
          </div>
        </div>
        {showAllLangs && (
          <ul className={style.langDropdown}>
            {langs.map(lang => (
              <li className={style.langItem} key={lang}>
                <Link
                  key={lang}
                  className={style.langText}
                  to={multilingualLink(newPathname, lang)}
                  onClick={() => setLangToStorage(lang)}
                >
                  <span className={style.flagStyle}>{lang.toUpperCase()}</span>
                  <div className={`${style.flagStyle}`}>{renderFlag(lang)}</div>
                </Link>
                <div className={style.flagStyle}>
                  {lang === locale && <CircleCheck />}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  function checkWindowSize() {
    if (window.innerWidth >= 1050) {
      setMenuVisibility(true)
    }
    if (window.innerWidth < 1050) {
      setMenuVisibility(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize)
    checkWindowSize()
  }, [])

  return (
    <>
      <div className={style.navbarWrapper}>
        <nav className={style.navbar}>
          <Link
            alt={'Enterosoft'}
            aria-label={'navigate-to-homepage'}
            to={multilingualLink('', storedLang)}
            onClick={checkWindowSize}
          >
            <Logo className={style.logo} />
          </Link>
          <div
            className={`${style.buttonWrapper} 
          ${menuVisibility ? style.open : style.close}`}
          >
            {showLangMenu('mobile', langs)}
            <button
              aria-label={'open-close-menu-button'}
              className={style.openMenuButton}
              onClick={showMenu}
            ></button>
          </div>
          {menuVisibility && (
            <div id="menu" className={style.menu}>
              {links.map((e, i) => (
                <Link
                  key={i}
                  onClick={checkWindowSize}
                  className={`${style.menuLink} menutab`}
                  to={multilingualLink(e.path, locale)}
                >
                  {e.sectionName}
                </Link>
              ))}

              <button
                onClick={() => {
                  openModal()
                  checkWindowSize()
                }}
                className={`${style.menuLink} ${style.contact} primary-button`}
              >
                {contact}
              </button>

              {showLangMenu('desktop', langs)}
            </div>
          )}
        </nav>
      </div>
    </>
  )
}

export default Navbar
