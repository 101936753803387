// extracted by mini-css-extract-plugin
export var buttonWrapper = "navbar-module--buttonWrapper--XB23+";
export var close = "navbar-module--close--2IoEj";
export var contact = "navbar-module--contact--irlDH";
export var dropdownArrow = "navbar-module--dropdownArrow--ABTSj";
export var dropdownShow = "navbar-module--dropdownShow--G2wX6";
export var flagStyle = "navbar-module--flagStyle--stiA4";
export var langDropdown = "navbar-module--langDropdown--2NEQA";
export var langDropdownShow = "navbar-module--langDropdownShow--ZhxI0";
export var langItem = "navbar-module--langItem--vxC11";
export var langText = "navbar-module--langText--a0rxI";
export var langTextMain = "navbar-module--langTextMain--GSY1W";
export var langWrapperDesktop = "navbar-module--langWrapperDesktop--3n3rE";
export var langWrapperMobile = "navbar-module--langWrapperMobile--MqLIz";
export var logo = "navbar-module--logo--Ulwf+";
export var menu = "navbar-module--menu--OXxEx";
export var menuLink = "navbar-module--menuLink--jlHKp";
export var navLangWrapper = "navbar-module--navLangWrapper--vCMr5";
export var navbar = "navbar-module--navbar--icVdy";
export var navbarLangButton = "navbar-module--navbar-lang-button--VLAyB";
export var navbarWrapper = "navbar-module--navbarWrapper--b+UJN";
export var open = "navbar-module--open--ySZax";
export var openMenuButton = "navbar-module--openMenuButton--vlK3C";
export var showup = "navbar-module--showup--KeJvb";