import React, { useEffect } from "react"
import "./layout.css"
import Navbar from "../../components/navbar/Navbar"
import { useLocale } from "../../hooks/useLocale"

export default function Layout({
    children,
    openModal,
    locale,
    isDefault,
    translations: {
        services,
        career,
        company,
        values,
        process,
        models,
        technology,
        contact,
    },
}) {
    const { changeLocale } = useLocale()
    useEffect(() => {
        changeLocale(locale)
    }, [locale])

    return (
        <>
            <Navbar
                openModal={openModal}
                services={services}
                career={career}
                company={company}
                values={values}
                process={process}
                models={models}
                technology={technology}
                contact={contact}
                locale={locale}
                isDefault={isDefault}
            />
            <div>{children}</div>
        </>
    )
}